import React, { useState } from 'react'
import { Box, Button, Group, Modal, Space, Text, Title, UnstyledButton } from '@mantine/core'
import { useMediumScreen } from '@/hooks'
import { Icon } from '@/components'
import { useRefreshStrideBalances } from '@/contexts/wallet'
import { useAirdropSwapBanner } from './AirdropSwapBannerProvider'
import { AirdropSwapBannerModalWidget, AirdropSwapBannerModalWidgetProps } from './AirdropSwapBannerModalWidget'

interface AirdropSwapBannerModalProps {
  denom?: 'stTIA' | 'stDYM'
}

// There are two layers for the Widget state
// A: AirdropProvider - takes care of modal state along with the banner
// B: AirdropSquidWidget (this) - takes care of the commit state and hooks on to the widget events
//
// @TODO: We previously used Squid Widget, but later replaced it with Leap Elements. We should
// consider renaming everything at some point, but not really a priority.
const AirdropSwapBannerModal: React.FC<AirdropSwapBannerModalProps> = ({ denom = 'stTIA' }) => {
  const { isMediumScreen } = useMediumScreen()

  const refreshStrideBalances = useRefreshStrideBalances()

  const { isOpen, close } = useAirdropSwapBanner()

  // UI state to display a success state when the swap transaction succeeds completely.
  // We currently don't handle error states since Leap Elements is supposed to handle that at least.
  const [isTxCommitted, setIsTxCommitted] = useState(false)

  // @TODO: Consider making a `status` state whose values are ['default', 'pending', 'success']
  // An internal state  if a transaction is currently on-going; used alongside `isAttemptingToClose`
  const [isPending, setIsPending] = useState(false)

  // UI state to display a prompt if the user attempts to close while transaction is on-going; used alongside `isPending`
  const [isAttemptingToClose, setIsAttemptingToClose] = useState(false)

  const handleSwapStart: AirdropSwapBannerModalWidgetProps['onSwapStart'] = () => {
    setIsPending(true)
  }

  const handleSwapComplete: AirdropSwapBannerModalWidgetProps['onSwapSuccess'] = (summary) => {
    if (summary.status !== 'success') return
    refreshStrideBalances()
    setIsPending(false)
    setIsAttemptingToClose(false)
    setIsTxCommitted(true)
  }

  // Close the modal and reset all state
  const handleClose = () => {
    close()
    setIsPending(false)
    setIsAttemptingToClose(false)
    setIsTxCommitted(false)
  }

  // If the user tries to close a pending transaction, we'll show a prompt before letting them go.
  const handleAttemptClose = () => {
    if (isPending) {
      setIsAttemptingToClose(true)
    } else {
      handleClose()
    }
  }

  const handleTrackProgress = () => {
    setIsAttemptingToClose(false)
  }

  return (
    <>
      <Modal
        opened={isOpen}
        onClose={handleAttemptClose}
        // We'll prevent users from accidentally closing the modal when the note is active
        closeOnEscape={!isAttemptingToClose}
        withCloseButton={false}
        size={isMediumScreen ? 460 : undefined}
        padding="md"
        styles={
          isMediumScreen ? {} : { inner: { padding: 0 }, modal: { minHeight: '100%' }, body: { height: '100%' } }
        }>
        {!isTxCommitted && !isMediumScreen && (
          <Group pt="xs" pr="xs">
            <UnstyledButton onClick={handleAttemptClose} ml="auto" p="sm">
              <Icon name="cross" sx={(t) => ({ color: t.colors.gray[8] })} size={8} />
            </UnstyledButton>
          </Group>
        )}

        {isTxCommitted ? (
          <>
            <Box p="sm">
              <Group spacing="xs">
                <Icon name="checkCircle" sx={(t) => ({ color: t.colors.cyan[5] })} size={14} />

                <Title
                  order={4}
                  sx={(t) => ({
                    color: t.colors.gray[9]
                  })}>
                  Success!
                </Title>
              </Group>

              <Space h={12} />

              <Text color="gray">
                You should be able to see your {denom} balance in your wallet. A new snapshot will be taken within 24
                hours and your rewards will update.
              </Text>

              <Space h="md" />

              <Group spacing="sm">
                <Button variant="outline" color="dark" onClick={handleClose}>
                  Close
                </Button>
              </Group>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ display: isAttemptingToClose ? 'none' : 'block' }}>
              <AirdropSwapBannerModalWidget
                denom={denom}
                onSwapStart={handleSwapStart}
                onSwapSuccess={handleSwapComplete}
              />
            </Box>

            {isAttemptingToClose && (
              <Box p="sm">
                <Group spacing="xs">
                  <Icon name="processing" sx={(t) => ({ color: t.colors.gray[9] })} size={14} />

                  <Title
                    order={4}
                    sx={(t) => ({
                      color: t.colors.gray[9]
                    })}>
                    Track transaction progress?
                  </Title>
                </Group>

                <Space h={12} />

                <Text color="gray">
                  This swap may take up to 20 minutes.{' '}
                  <strong>You won't be able to track the transaction if you close this modal</strong>, but it will keep
                  going in the background.
                </Text>

                <Space h="xs" />

                <Text color="gray">
                  Once the swap to {denom} succeeds, you will be able to see your {denom} balance in your wallet. A new
                  snapshot will be taken within 24 hours and your rewards will update.
                </Text>

                <Space h="md" />

                <Group spacing="sm">
                  <Button onClick={handleTrackProgress}>Go back</Button>

                  <Button variant="outline" color="dark" onClick={handleClose}>
                    Close
                  </Button>
                </Group>
              </Box>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export { AirdropSwapBannerModal }
