import React from 'react'
import * as Popover from '@radix-ui/react-popover'
import { createStyles, keyframes } from '@mantine/core'
import { useMediumScreen } from '@/hooks'

interface CoinSelectorPopoverProps {
  trigger: React.ReactNode
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(8px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' }
})

const useStyles = createStyles<string, { isMediumScreen: boolean }>((t, params) => ({
  content: {
    borderRadius: 4,
    width: params.isMediumScreen ? 434 : 340,
    backgroundColor: t.white,
    willChange: 'transform, opacity',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    '&[data-state="open"]': {
      //   '&[data-side="top"]': { animationName: slideDownAndFade },
      //   '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade }
      //   '&[data-side="left"]': { animationName: slideRightAndFade }
    },
    boxShadow: t.shadows.lg,
    zIndex: 998
  },

  arrow: {
    fill: t.white
  }
}))

// @TODO: Consider making this reusable and replacing MantinePopover with this. There is no
// real incentive to do this yet. We can probably do this once we move out of Mantine and over
// to Tailwind. On the other hand, this popover fixes an issue with MantinePopover being positioned
// out of place when opened while mounting initially.
const CoinSelectorPopover = (props: React.PropsWithChildren<CoinSelectorPopoverProps>) => {
  const { isMediumScreen } = useMediumScreen()

  const { classes } = useStyles({
    isMediumScreen
  })

  return (
    <Popover.Root open={props.isOpen} onOpenChange={props.onOpenChange}>
      <Popover.Trigger asChild>{props.trigger}</Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className={classes.content} align="start" side="bottom" alignOffset={8} sideOffset={16}>
          {props.children}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}

export { CoinSelectorPopover }
